import React, {  useEffect, useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import axios from 'axios';
import { Alert, Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import * as yup from 'yup';
import { useFormik } from "formik";
import {  IconDownload } from '@tabler/icons';
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useSelector } from "react-redux";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import DownloadSampleFile from "./DownloadSampleFile";
import FailureNotification from "ui-component/FailureNotification";
import SuccessNotification from "ui-component/SuccessNotification";

const icons = { IconDownload,DeleteOutlinedIcon };


const useStyles = makeStyles((theme) => ({
    root: {},
    ActionItems: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    mainalert: {
      width: "100%",
    },
    table: {
        "& .MuiTableRow-root": {
          border: '1px solid #ececec'
        },
        "& .MuiTableCell-body":{
          padding:'5px 10px',
          border: '1px solid #ececec',
        },
        "& .MuiTableCell-head":{
            padding:theme.spacing(2),
            border: '1px solid #ececec',
            fontWeight:1000,
            background: '#4F81BD',
            color: 'white'
        }
      },
      cell:{
  
      },
      card:{
          "& .MuiPaper-root":{
              marginTop:theme.spacing(1)
            },
      },
      redIcon:{
          color:'red'
      },
      greenIcon:{
          color:'green'
      }
}));



const ImportUser = ()=>{
    const { REACT_APP_API_ENDPOINT, REACT_APP_OKTA_DISABLE_DEFAULT_APPS } = process.env
    const DisableDefaultOktaApps = REACT_APP_OKTA_DISABLE_DEFAULT_APPS.split(",")

    const classes = useStyles();
    const [appArray, setAppArray] = React.useState([]);
    const [progress, setProgress] = React.useState(0);
    const [progressFlag, setProgressFlag] = React.useState(0);
    const [validateLoader, setValidateLoader] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    //eslint-disable-next-line
    const [openAlert, setOpenAlert] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [severity, setSeverity] = React.useState("info");
    // const [allGroupArray, setAllGroupArray] = React.useState([]);
    const [ groupArray, setGroupArray] = React.useState([]);
    //eslint-disable-next-line
    const [listOfFile, setListOfFile] = React.useState([]);
    const [filterListOfFile, setFilterListOfFile] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [count, setCount] = React.useState(0);
    const [rowPerPage, setRowsPerPage] = React.useState(5);
    //eslint-disable-next-line
    const[Percentage, setPercentage] = useState(0)
    const[TotalCount, setTotalCount] = useState(0)
    const[FinishedCount, setFinishedCount] = useState(0)
    const[intervalFlag, setIntervalFlag] = useState(false) 
    const [ToggleDialog, setToggleDialog] = useState(false);

     //********* || ALERTS  || ****************/

     const [openError, setopenError] = React.useState(false);
     const [ErrorMessage, setErrorMessage] = React.useState("");
     const [openSuccess, setOpenSuccess] = React.useState(false);
     const [SuccessMessage, setSuccessMessage] = React.useState("");

    const state = useSelector(state => state);
    const roles = state.authUser.roles;

    let roleID = roles.map((role) => role.split("partneriam-")[1]);
    let adminFind = roleID.includes("admin");

    // const getGroups = async () => {
    //     await axios.get(`${REACT_APP_API_ENDPOINT}/groups/get-groups`)
    //         .then((res) => {
    //             let groupOptions = []
    //             res.data?.data.body.map((item, key) => {
    //                 groupOptions.push({
    //                     'label': item.profile.name,
    //                     'id': item.id
    //                 })
    //                 return item
    //             })
    //             // if(groupOptions.length === 1){
    //             //     formik.setFieldValue("groupIds", [groupOptions[0].id])
    //             // }
    //             setAllGroupArray(groupOptions)
    //     })
    // }

    const getApps = async (searchValue) => {
        await axios.post(`${REACT_APP_API_ENDPOINT}/apps/get-apps?q=${searchValue}&limit=200`,{
            "filterType": 2,
            "value": "ACTIVE"
            }).then((res) => {
                let appOptions = []
                let appOptionsRoles = []
                res.data.data?.body.map((item, key) => {
                    if(!DisableDefaultOktaApps.includes(item.id)){
                        appOptions.push({
                            'label': item.label,
                            'id': item.id
                        })
                    }
                    return item
                })
                if (adminFind) {
                    setAppArray(appOptions)
                    if(appOptions.length === 1){
                        formik.setFieldValue("appId", appOptions[0].id)
                    }
                } else {
                    roleID.map((value) => {
                        appOptions.map((item) => {
                            if (item.id === value) {
                                appOptionsRoles.push({
                                    'label': item.label,
                                    'id': item.id
                                })
                            }
                            return item
                        })
                        return value
                    })
                    setAppArray(appOptionsRoles)
                    if(appOptionsRoles.length === 1){
                        // console.log({appOptionsRoles}, "First App::: ",appOptionsRoles[0].id)
                        formik.setFieldValue("appID", appOptionsRoles[0].id)
                    }
                }
            }
            )
            .catch((err) => {
                if(err.response.status === 429){
                    setopenError(true);
                    setErrorMessage('Too Many Requests')
                }else{
                    console.log(err)
                }
            })
    }
    
    const getListOfFile = async()=>{
        await axios.get(`${REACT_APP_API_ENDPOINT}/users/list-of-file/1`)
            .then(res=>{
                setListOfFile(res.data.data);
                setFilterListOfFile(res.data.data);
                setCount(res.data.data.length);
                setPercentage(res.data.data[0]?.percentage)
                if(res.data.data.length > 0){
                let Total = 0
                let Finished = 0
                res.data.data.map( (item) => {
                    Total += item.total
                    Finished += item.finished
                    return item
                })
                setTotalCount(Total)
                setFinishedCount(Finished)
                    // if(Total !== Finished){
                    //     setIntervalFlag(true)
                    // }else{
                    //     setIntervalFlag(false)
                    // }
                }
                else{
                    setIntervalFlag(false)
                }
                
            }).catch(err=>{
                console.log(err);
            })
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    React.useEffect(()=>{
        getApps("");
        // getGroups();
        getListOfFile();
        //eslint-disable-next-line
    },[])


      let validationObject = {
        appID: yup.string()
            .required('Application Name is required'),
        groupIds:yup.array().test("required","Please select atleast one group",value=>{
            if(value.length>0){
                if(value.filter(item=>item===undefined).length>0){
                    return false;
                }
                return true;
            }
            return false;
        }),
        file: yup.mixed().required("Please select file").test(
                    "fileSize",
                    "File size limit exceed",
                    value => value && value.size <= (5000000)
        ),
        isSendEmail:yup.string().required(" Please select value")
    }
    let initialValues = {
        appID: '',
        groupIds:[],
        file:null,
        isSendEmail:'1'
    }
    const validationSchema = yup.object().shape(validationObject)
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        validateOnBlur: true,
        onSubmit: (values,props) => {
           setProgress(0); 
           setOpen(true);
           setProgressFlag(true);
           uploadFile(values.file,values.appID,values.groupIds,values.isSendEmail);
        }
    });

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    const handleClose = () => {
        setOpen(false);
    };


   //***************** || Alert Handle Close  || ************************* */ 

        const handleCloseAlertError = () => {
            setErrorMessage("");
            setopenError(false);
        };

        const handleCloseAlertSuccess = () => {
            setSuccessMessage("");
            setOpenSuccess(false);
        };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        formik.setFieldValue('file',files[0]);
    };

 
    const uploadFile = async (file,applicationId,groupIds,isSendEmail)=>{
        let form =  new FormData();
        form.append("file",file);
        form.append("applicationId",applicationId);
        form.append("groupIds",groupIds);
        form.append("isSendEmail",isSendEmail==="1");
        await axios.post(`${REACT_APP_API_ENDPOINT}/users/import-user`,form,
        {
            onUploadProgress: (progressEvent) => {
                const progress = (progressEvent.loaded / progressEvent.total) * 100;
                setProgress(progress);
                if(progress===100){
                    setValidateLoader(true);
                    setProgressFlag(false);
                }
            },
        }
        ).then((res)=>{
            // setIntervalFlag(true)
            setValidateLoader(false);
            if(res.data.responseStatus==="FAILURE"){
                if(res.data.data?.isValid){
                    if(!res.data.data?.isOkta){ 
                        setSeverity('error')
                        setMessage("At row "+ res.data.data?.row+", message : "+res.data.data?.errMsg?.errorSummary )
                    }
                }
                else{
                    setSeverity('error')
                    setMessage(res.data.data.errorMsg ? res.data.data.errorMsg : res.data.messgae);
                }
            }else{
                setSeverity('success')
                setMessage(res.data.messgae)
            }
        }).catch(error=>{
            setValidateLoader(false);
            setSeverity('error');
            setMessage('Something went wrong')
        })
    }

    
    const getGroupsByApp = async (oktaAppID) => {
        await axios
            .get(`${REACT_APP_API_ENDPOINT}/apps/get-groups-by-app/${oktaAppID}?limit=1000`)
            .then((res) => {
                let optionsGroup = []
                res.data?.data?.body.map((item) => {
                    optionsGroup.push({
                        id: item?.id,
                        label: item?._embedded?.group?.profile?.name
                    })
                    return item
                })
                setGroupArray(optionsGroup)
                if(optionsGroup.length === 1){
                    if(formik?.values?.appID){
                        formik.setFieldValue("groupIds", [optionsGroup[0].id])
                    }
                }
            })
            .catch((err) => {
                if(err.response.status === 429){
                    setopenError(true);
                    setErrorMessage('Too Many Requests')
                }else{
                    console.log(err)
                }
            })
    }

    const RefreshGroupByApps = async (oktaAppID) => {
        await axios
            .get(`${REACT_APP_API_ENDPOINT}/apps/refresh/${oktaAppID}?limit=1000`)
            .then((res) => {
                let optionsGroup = []
                res.data?.data?.body.map((item) => {
                    optionsGroup.push({
                        id: item?.id,
                        label: item?._embedded?.group?.profile?.name
                    })
                    return item
                })
                setGroupArray(optionsGroup)
                if(optionsGroup.length === 1){
                    if(formik?.values?.appID){
                        formik.setFieldValue("groupIds", [optionsGroup[0].id])
                    }
                }
            })
            .catch((err) => {
                if(err.response.status === 429){
                    setopenError(true);
                    setErrorMessage('Too Many Requests')
                }else{
                    console.log(err)
                }
            })
    }

    React.useEffect(()=>{
        if(formik.values.appID){
            getGroupsByApp(formik.values.appID);
        }
        //eslint-disable-next-line
    },[formik.values.appID])

    function printDate(timestamp){
        const date =  new Date(timestamp);
        return date.getFullYear()+'-'+date.getMonth()+'-'+date.getDate()+' '+date.getHours()+':'+date.getMinutes()+':'+date.getSeconds();
    }

    const downloadStatusFile = async(folderId)=>{
        await axios.get(`${REACT_APP_API_ENDPOINT}/users/download-status-file/${folderId}/1`,{
            responseType: 'blob'
        })
        .then(response=>{
            const type = response.headers['content-type']
            const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'status.xlsx'
            link.click()
        }).catch(err=>{
            // setIntervalFlag(true)
        })
    }   

    const onRemoveFile = async(folderId) =>{
        await axios.get(`${REACT_APP_API_ENDPOINT}/users/remove-folder/${folderId}/1`)
        .then(res=>{
            if(res.data.data){
                getListOfFile();
            }
        }).catch(error=>{
            console.log(error);
        })
    }

    //************* || PROGRESS BAR PERCENTAGE   || *********** */

    useEffect(() => {
        if(FinishedCount === TotalCount){
            setIntervalFlag(false)
        }
        //eslint-disable-next-line
    },[FinishedCount])
 
    
    useEffect( () => {
        if(!intervalFlag){
            return;
        }
        const Timer = setInterval(getListOfFile, 5000)
        return () => {
            clearInterval(Timer)
        }
        //eslint-disable-next-line
    },[intervalFlag])

    const handleDialog = () => {
        setToggleDialog(!ToggleDialog)
    }
    

    return(
        <>
        <FailureNotification OpenError={openError} handleClose={handleCloseAlertError} ErrorMessage={ErrorMessage} />
        <SuccessNotification openSuccess={openSuccess} handleAlertSuccess={handleCloseAlertSuccess} SuccessMessage={SuccessMessage} />
        <MainCard title='Bulk User Upload' className={classes.card} id="Main">
            <Box
                component='form'
                sx={{
                    '&.MuiTextField-root': { m: 0 },
                    flexGrow: 1
                }}
                onSubmit={formik.handleSubmit}
            >
                <Grid container spacing={4}>
                    <Grid item xs={6} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Autocomplete
                            fullWidth
                            options={appArray}
                            name='appID'    
                            onChange={ (e , value) => {
                                formik.setFieldValue("appID", value?.id)
                            }}
                            onBlur={formik.handleBlur}
                            renderInput={ (params) => (
                                <TextField 
                                    {...params}
                                    label="Application Name*"
                                    onChange={ (e) => {
                                        getApps(e.target.value)
                                    }}
                                    error={Boolean(formik.errors.appID)}
                                    helperText={formik.errors.appID}
                                />
                            )}
                        />
                            {/* <MenuItem value="" style={{ display: 'none' }}>Please Select</MenuItem>
                            {appArray?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField> */}
                        <Typography variant="caption" fontSize="1rem" component="div" color="text.primary" alignItems="center" onClick={handleDialog} style={{cursor:'pointer',margin:'0.5%', textDecoration:'underline', color:'#0c94e8'}}>
                            Download Sample File For Reference
                        </Typography>
                    </Grid>
                    <Dialog
                    fullWidth={true}
                    maxWidth="xl"
                    open={ToggleDialog}
                    >
                            <CancelOutlinedIcon
                                    className="closeButton"
                                        style={{
                                            height: "35px",
                                            width: "35px",
                                            right:'0',
                                            position:'absolute',
                                            margin:'1%'
                                        }}
                             onClick={handleDialog}
                            />
                        <DialogContent>
                             <DownloadSampleFile />
                        </DialogContent>
                    </Dialog>

                    <Grid item xs={6} display="flex" flexDirection="column" justifyContent="right" alignItems="right">
                            <TextField
                                select
                                SelectProps={{
                                    multiple:true
                                }}
                                fullWidth
                                name='groupIds'
                                label='Group*'
                                value={formik?.values?.appID ? formik.values.groupIds : []}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.groupIds && Boolean(formik.errors.groupIds)}
                                helperText={formik.touched.groupIds && formik.errors.groupIds}
                            >
                                <MenuItem value="" style={{ display: 'none' }}>Please Select</MenuItem>
                            {formik?.values?.appID && groupArray?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.label}
                                </MenuItem>
                            ))}
                            </TextField>
                            {
                                formik.values.appID &&
                                <Typography variant="caption" fontSize="1rem" component="div" color="text.primary" onClick={() => RefreshGroupByApps(formik.values.appID)}  style={{cursor:'pointer',margin:'0.5%', textDecoration:'underline', color:'#0c94e8', textAlign: 'right'}}>
                                    Refresh
                                </Typography>
                            }
                    </Grid>
                    <Grid item xs={6}>
                       <TextField
                        fullWidth
                        label="file*"
                        value={formik.values.file?.name || ""}
                        error={formik.touched.file && Boolean(formik.errors.file)}
                        helperText={formik.touched.file && formik.errors.file}
                        InputProps={{
                            endAdornment:(
                                <InputAdornment position="end">
                                    <Button
                                    variant="contained"
                                    component="label"
                                    size="small"
                                    >
                                        Upload File
                                        <input
                                            type="file"
                                            name="file"
                                            id="file"
                                            onChange={handleFileChange}
                                            hidden
                                        />
                                    </Button>
                             </InputAdornment>
             
                            )
                        }}
                       ></TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            select
                            name='isSendEmail'
                            id="isSendEmail"
                            fullWidth
                            label="Do you want to send activation email?"
                            value={formik.values.isSendEmail}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.isSendEmail && Boolean(formik.errors.isSendEmail)}
                            helperText={formik.touched.isSendEmail && formik.errors.isSendEmail}
                        >
                            <MenuItem value="" style={{ display: 'none' }}>Please Select</MenuItem>
                            <MenuItem value="1">Yes</MenuItem>
                            <MenuItem value="0">No</MenuItem>
                        </TextField>
                    </Grid>
                   
                    <Grid item xs={12}>
                        <Button color="primary" variant="contained"  type="submit" style={{marginRight:5}} disabled={!(formik.isValid && formik.dirty)}>
                            Submit
                        </Button>
                        <Button color="primary" variant="outlined" type="reset" onClick={formik.resetForm}>
                            Clear
                        </Button>   
                    </Grid>
                </Grid>
            </Box>
           
        
            <Grid container justifyContent="flex-end">
                <Grid item>
                    <Button variant="outlined" 
                    onClick={()=> {
                        // setIntervalFlag(true)
                        getListOfFile()
                    }}
                    >
                        Refresh
                    </Button>
                </Grid>
            </Grid>
            <TableContainer component={Paper} style={{marginTop:"1rem"}}>
                <Table 
                    sx={{ minWidth: 650, padding: 0, margin: 0 }} 
                    aria-label="simple table" 
                    className={classes.table}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Id</TableCell>
                            <TableCell align="center">Files</TableCell>
                            <TableCell align="center">Created On</TableCell>
                            <TableCell align="center">Progress</TableCell>
                            <TableCell align="center">Download status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            filterListOfFile.length>0?
                            filterListOfFile
                            .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                            .map((row, id) => (
                                <TableRow
                                    key={id}
                                    style ={ id % 2? { background : "white" }:{ background : "#eaf0ff" }}
                                >
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{
                               (row.files[0] ? row.files[0].substring((row.files[0].lastIndexOf("\\")===-1?row.files[0].lastIndexOf("/"):row.files[0].lastIndexOf("\\"))+1):"")+
                               (row.files[1] ? ", "+row.files[1].substring((row.files[1].lastIndexOf("\\")===-1?row.files[1].lastIndexOf("/"):row.files[1].lastIndexOf("\\"))+1):"")
                                }
                               </TableCell>
                                <TableCell>{
                                 printDate(row.createdOn)   
                                }</TableCell>
                                <TableCell align="center">
                                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                <CircularProgress variant="determinate"  value={row.percentage} />
                                    <Box
                                    sx={{
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        position: 'absolute',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                    >
                                    <Typography variant="caption" component="div" color="text.secondary">
                                        {`${row.percentage ? Math.round(row.percentage) : 0}%`}
                                    </Typography>
                                    </Box>
                                   </Box>
                                </TableCell>
                                <TableCell>
                                    {
                                        row.percentage === 100 ? 
                                            <div>
                                            <IconButton className={classes.greenIcon} onClick={()=>downloadStatusFile(row.name)}>
                                                   <icons.IconDownload></icons.IconDownload>
                                            </IconButton>
                                            <IconButton className={classes.redIcon} onClick={()=>onRemoveFile(row.name)}>
                                                <icons.DeleteOutlinedIcon></icons.DeleteOutlinedIcon>
                                            </IconButton>
                                            </div>
                                            : "Please Wait For the File to Process"
                                    }
                                </TableCell>
                                </TableRow>
                            ))
                            :
                            <>
                                <TableRow>
                                    <TableCell colSpan={5} component="th" scope="row" style={{textAlign: 'center',fontWeight:'bold'}}>
                                        No Record Found
                                    </TableCell>
                                </TableRow>
                            </>
                        }
                    </TableBody>
                   
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={count}
                rowsPerPage={rowPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
          
        </MainCard>
            <Dialog
                open={open}
                maxWidth="sm"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                </DialogTitle>
                <DialogContent sx={{textAlign:"center",overflow:'hidden'}}>
                {
                    progressFlag?
                        <>
                        <CircularProgress size={150} variant="determinate" value={progress} />
                            <Box
                                sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                }}
                            >
                    
                            <Typography variant="caption" fontSize="2rem" component="div" color="text.secondary">
                            {`${Math.round(progress)}%`}
                            </Typography>
                    
                            </Box>
                        </>
                        
                    :
                    <>
                       { 
                        validateLoader ?
                        <>
                            <CircularProgress size={150} color="secondary"  />
                            <Box
                                sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                }}
                            >
                    
                            <Typography variant="caption" fontSize="1rem" component="div" color="text.secondary">
                             Processing
                            </Typography>
                    
                            </Box>
                     
                        </>
                        :
                        <>
                            <Alert severity={severity} variant="filled" sx={{ width: "100%" }} onClose={handleCloseAlertSuccess}>
                                <strong>{message}</strong>
                            </Alert>
                        </>
                        }   
                    </>
                }
            
                       
                   
                </DialogContent>
                <DialogActions>
                    <Button varient="outlined" onClick={handleClose}>Done</Button>
                </DialogActions>
            </Dialog>
        </>

    )
}
export default ImportUser;