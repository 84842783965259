import { Alert, AlertTitle, Button, Grid, Paper, Slide, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import MainCard from 'ui-component/cards/MainCard'

import { makeStyles } from "@mui/styles";
import { useConfirm } from 'material-ui-confirm'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useSelector } from 'react-redux';
import FailureNotification from 'ui-component/FailureNotification';
import SuccessNotification from 'ui-component/SuccessNotification';
import Loader from 'ui-component/Loader';

//eslint-disable-next-line
const useStyles = makeStyles((theme) => ({
  root: {
    "& .css-6hp17o-MuiList-root-MuiMenu-list":{
      width:'160px'
    }
  },
  table: {
    "& .MuiTableRow-root": {
      border: '1px solid #ececec'
    },
    "& .MuiTableCell-body": {
      border: '1px solid #ececec'
    },
    "& .MuiTableCell-head": {
      padding:theme.spacing(1),
      border: '1px solid #ececec',
      fontWeight:1000,
      background: '#4F81BD',
      color: 'white'
    },
  },
  ActionItems: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: 'sticky',
    top: '0',
    alignSelf: 'flex-start'
  },
  TableButton: {
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center'
  },
  TopBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
}));

function Activate() {

  const classes = useStyles()
  const navigate = useNavigate()
  let { type } = useParams()
  const data = useLocation()
  const [Deactivated, setDeactivated] = useState([])
  const [ShowMoreButton, setShowMoreButton] = useState(false)
  const [PageAfter, setPageAfter] = useState(null)
  const [Loader1, setLoader] = useState(false)
  const state = useSelector(state => state);
  const roles = state.authUser.roles;

  let roleID = roles.map((role) => role.split("partneriam-")[1]);
  let adminFind = roleID.includes("admin");

  //********* || ALERTS  || ****************/

const [openError, setopenError] = useState(false);
const [ErrorMessage, setErrorMessage] = useState("");
const [openSuccess, setOpenSuccess] = useState(false);
const [SuccessMessage, setSuccessMessage] = useState("");

  const {REACT_APP_API_ENDPOINT} = process.env
  
  let filterSearchWord
  if(type === 'reactivate-provisioned'){
    type = 'reactivate'
    filterSearchWord = "PROVISIONED"
  }else if( type === 'reactivate-recovery'){
    type = 'reactivate'
    filterSearchWord = "RECOVERY"
  }else if( type === 'activate'){
    filterSearchWord = "STAGED"
  }else if( type === 'deactivate'){
    filterSearchWord = "ALL-EXCEPT-DEPROVISIONED"
  }else if(type === 'unlock'){
    filterSearchWord = "LOCKED_OUT"
  }else if(type === 'activate-deprovisioned'){
    type='activate'
    filterSearchWord = "DEPROVISIONED"
  }
  


  const GetSelectedOperationData = async () => {
    setLoader(true)
    adminFind ? (
    await axios.post(`${REACT_APP_API_ENDPOINT}/users/get-users?limit=50`,{
      filterType: '1',
      value: filterSearchWord
    }).then( res => {
      setLoader(false)
      //  console.log(res.data.data.body)
       setDeactivated(res.data.data.body)
       if(res.data.data.body.length === 0){
        setopenError(true)
        setErrorMessage("No Users Found")
       }
       setShowMoreButton(true)
       if (res.data.data?.nextLink?.length === 1) {
        setShowMoreButton(false)
      }
        const url = res.data.data.nextLink[0].split(';')[0]
        let suppressedURL = url.match(/<(.*?)>/)
        const after = new URL(suppressedURL[1]).searchParams.get("after")
        setPageAfter(after)
    }).catch( err => {
      setLoader(false)
       console.log(err)
    })
   ) :
   (
    await axios.post(`${REACT_APP_API_ENDPOINT}/apps/get-assigned-users-of-app/${data.state.appId}?limit=1000`,{
      filterType: '1',
      value: filterSearchWord
    }).then( res => {
      setLoader(false)
      //  console.log(res.data.data.body)
       setDeactivated(res.data.data.body)
       if(res.data.data.body.length === 0){
        setopenError(true)
        setErrorMessage("No Users Found")
       }
       setShowMoreButton(true)
       if (res.data.data?.nextLink?.length === 1) {
        setShowMoreButton(false)
      }else if(res.data.data?.nextLink === undefined){
        setShowMoreButton(false)
      }
      if(res.data.data?.nextLink !== undefined){
        const url = res.data.data.nextLink[0].split(';')[0]
        let suppressedURL = url.match(/<(.*?)>/)
        const after = new URL(suppressedURL[1]).searchParams.get("after")
        setPageAfter(after)
      } 
    }).catch( err => {
      setLoader(false)
       console.log(err)
    })
   )
  }
  useEffect(() => {
    GetSelectedOperationData()
 //eslint-disable-next-line
  },[])

  //***************  ||  Handling Check boxes   ||     ****************** */

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const confirm = useConfirm()


  const AllEmails = Deactivated && Deactivated.map(item => {
    return item.id
  })

  useEffect(() => {
      setList(AllEmails);
      //eslint-disable-next-line
  }, [isCheck, Deactivated]);

  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(list.map(li => li));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleCheckBoxClick = e => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== id));
    }
  };

  const PerformingUserOperation = () => {
    setLoader(true)
      confirm({ title: <p style={{fontSize: 'large'}}>{`Do you want to ${type[0].toUpperCase() + type.substring(1)} ${isCheck.length} Selected User(s) ?`}</p>,  description: `An activation email will be sent to each user informing them that their account is now active. You can edit the content of these emails on the customize email page.`})
      .then(async () => {
        await axios.post(`${REACT_APP_API_ENDPOINT}/users/operations-bulk-user`,{
          userIds : isCheck,
          operationType: type
        }).then( res =>{
          setLoader(false)
           setOpenSuccess(true)
           setSuccessMessage(res.data.messgae)
           GetSelectedOperationData()
           setIsCheck([])
           setIsCheckAll(false);
        })
      })
      .catch((err) => {
        setLoader(false)
        setopenError(true);
        setErrorMessage("Cancelled Request")
      })
  }

//*********** || Get AllUsersPagination Users After     || *************** */

const AllUsersPaginationButton = async () => {
  setLoader(true)
  await axios
  .post(`${REACT_APP_API_ENDPOINT}/users/get-users?limit=50&after=${PageAfter}`, { filterType: '1',  value: filterSearchWord} )
  .then((res) => {
    setLoader(false)
    res.data.data.body.map((item) => {
      Deactivated.push(item)
      return item
    })
      const url = res.data.data.nextLink[0].split(';')[0]
      let suppressedURL = url.match(/<(.*?)>/)
      const after = new URL(suppressedURL[1]).searchParams.get("after")
      setPageAfter(after)
    
    if (res.data.data.nextLink.length === 1) {
      setShowMoreButton(false)
    }
  })
  .catch((err) => {
    setLoader(false)
    console.log(err)
  });
}



//***************** || Alert Handle Close  || ************************* */ 

const handleCloseAlertError = () => {
  setErrorMessage("");
  setopenError(false);
};

const handleCloseAlertSuccess = () => {
  setSuccessMessage("");
  setOpenSuccess(false);
};


  return (
    <>
     <FailureNotification OpenError={openError} handleClose={handleCloseAlertError} ErrorMessage={ErrorMessage} />
     <SuccessNotification openSuccess={openSuccess} handleAlertSuccess={handleCloseAlertSuccess} SuccessMessage={SuccessMessage} />
     {
        Loader1 ?
          <div style={{ margin: '0.5%' }}>
            <Loader />
          </div>
          :
          null
      }
    <Snackbar
        open={openError}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleCloseAlertSuccess}
        TransitionComponent={Slide}
        transitionDuration={{ enter: 1000, exit: 2000 }}
        TransitionProps={{ enter: true, exit: false }}
      >
        <Alert severity="error" variant="filled" onClose={handleCloseAlertError} >
          <AlertTitle>Error</AlertTitle>
          <strong>{ErrorMessage}</strong>
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSuccess}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={handleCloseAlertSuccess}
        TransitionComponent={Slide}
        transitionDuration={{ enter: 1000, exit: 2000 }}
        TransitionProps={{ enter: true, exit: false }}
        sx={{
          color: "secondary",
          "& .MuiSnackbarContent-root": { backgroundColor: "green" }
        }}
      >
        <Alert severity="success" variant="filled" onClose={handleCloseAlertSuccess}>
          <AlertTitle>Success</AlertTitle>
          <strong>{SuccessMessage}</strong>
        </Alert>
      </Snackbar>
      <MainCard title={`${type[0].toUpperCase() + type.substring(1)} Users`} id="Main">
      {/* <Grid container marginBottom={"3%"} >
          <Grid item xs={6} >
                <TextField
                  fullWidth
                  id="searchInput"
                  name="searchInput"
                  label="Search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="end">
                        {" "}
                        <IconSearch stroke={1.5} size="1.2rem" />{" "}
                      </InputAdornment>
                    ),
                  }}
                />
            </Grid>
      </Grid> */}
      <Grid container spacing={1} className={classes.ActionItems}>
        <Grid item xs={3} style={{marginBottom:'1%'}}>
            <Button 
            variant='contained' 
            size='large' 
            disabled={isCheck.length>0 ? false : true}
            onClick={PerformingUserOperation}
            >
                {type} Selected ({ isCheck.length})
          </Button >
        </Grid>
        <Grid item xs={2} style={{marginBottom:'1%'}}>
          <Button
            variant='outlined'
            size='large' 
            onClick={() => navigate('/user/list-users')}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
      
      <TableContainer component={Paper} >
                <Table
                stickyHeader
                sx={{ minWidth: 650, padding: 0, margin: 0 }}
                aria-label="simple table"
                className={classes.table}
              >
                <TableHead>
                    <TableRow>
                      <TableCell align="center">
                      <input type="checkbox" name='SelectAll' onChange={handleSelectAll} checked={isCheckAll}/>
                      </TableCell>
                      <TableCell align="center">Person & username</TableCell>
                      <TableCell align="center">Primary Email</TableCell>
                      <TableCell align="center">Status</TableCell>
                    </TableRow>
              </TableHead>
              <TableBody>
                {
                  Deactivated && 
                    Deactivated.map( (item, key) => {
                      return(
                        <TableRow key={key} style ={ key % 2? { background : "white" }:{ background : "#eaf0ff" }}>
                        <TableCell component="th" scope="row" align='center'>
                          <input type="checkbox" name={item.profile.email} id={item.id} onChange={handleCheckBoxClick} checked={isCheck.includes(item.id)} />
                        </TableCell>
                        <TableCell align="left">{adminFind ? item.profile.firstName : item._embedded?.user?.profile?.firstName}</TableCell>
                        <TableCell align="left">{ adminFind ? item.profile.email : item._embedded?.user?.profile?.email }</TableCell>
                        <TableCell align="left">{adminFind ? item.status : item._embedded?.user?.status }</TableCell>
                      </TableRow>
                      )
                    })
                }
              </TableBody>
                </Table>
                
      </TableContainer>
      {
            ShowMoreButton ?
              <div className={classes.TableButton}>
                <Button onClick={ () => AllUsersPaginationButton()} variant="text" size="large" style={{ fontSize: '17px', marginTop: '1%' }} >
                  Show More
                </Button>
              </div>
              : null
          }
    </MainCard>
    </>
    
  )
}

export default Activate